import { useMemo } from 'react'
import { IconName, Color } from '@revolut/ui-kit'
import { ImageProp } from '@revolut/ui-kit/types/dist/types'
import { useGetSelectors } from '@src/api/selectors'
import { selectorKeys } from '@src/constants/api'
import useIsCommercial from '@src/hooks/useIsCommercial'
import { IdAndName } from '@src/interfaces'
import { UpdateTypes } from '@src/interfaces/kpis'

import { updateTypeOptionMap } from './updateTypeOptionMap'
import { useSelector } from 'react-redux'
import { selectPermissions } from '@src/store/auth/selectors'
import { useGetRoadmapSettings } from '@src/api/settings'

export interface MetricTypeSelectorValue {
  name?: string
  description?: string
  image?: ImageProp
  icon?: IconName
  iconBackground?: Color
  connected?: boolean
  id?: UpdateTypes
}

interface Selector extends IdAndName<UpdateTypes> {
  value: MetricTypeSelectorValue
}

export const useMetricTypeSelector = (): Selector[] => {
  // selector returns all connected types
  const { data: connectedSelectors, isLoading } = useGetSelectors<IdAndName<UpdateTypes>>(
    selectorKeys.goal_target_types,
  )

  const { data: roadmapsSettings, isLoading: isRoadmapsLoading } = useGetRoadmapSettings()

  const isCommercial = useIsCommercial()
  const permissions = useSelector(selectPermissions)

  const parsed: Selector[] = useMemo(() => {
    const connectedMap = new Map()
    connectedSelectors?.forEach(selector => {
      return connectedMap.set(selector.id, selector)
    })

    return (
      Object.keys(updateTypeOptionMap) as Array<keyof typeof updateTypeOptionMap>
    ).reduce((acc: Selector[], key) => {
      // hide SQL if commercial
      if (key === UpdateTypes.sql && isCommercial) {
        return acc
      }
      const requiredInregrationPermission =
        updateTypeOptionMap[key].requiredIntegrationPermission
      // show as connected
      if (connectedMap.get(key)) {
        // do not show JIRA if it's connected but disabled in the settings
        if (key === UpdateTypes.roadmap && !roadmapsSettings?.jira_epics_enabled) {
          return acc
        }
        acc.push({
          id: key,
          name: key,
          value: {
            ...updateTypeOptionMap[key],
            id: key,
            connected: true,
          },
        })
        // check if integration permission required and if user has them
      } else if (
        requiredInregrationPermission
          ? permissions.includes(requiredInregrationPermission)
          : true
      ) {
        acc.push({
          id: key,
          name: key,
          value: { ...updateTypeOptionMap[key], id: key, connected: false },
        })
      }

      return acc
    }, [])
  }, [connectedSelectors, roadmapsSettings?.jira_epics_enabled, isCommercial])

  return isLoading || isRoadmapsLoading ? [] : parsed
}
