import { Avatar, Widget, Flex, Icon, Placeholder, Token } from '@revolut/ui-kit'
import { UpdateTypes } from '@src/interfaces/kpis'
import React from 'react'
import { updateTypeOptionMap } from './updateTypeOptionMap'
import { FormattedMessage } from 'react-intl'
import { askClickupWorkspacePermissions } from '@src/api/officeSuiteProvider'
import { lookerIntegrationPreferences } from '@src/api/integrations'

import { newTabTo } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'

interface Props {
  type: UpdateTypes
}

export const NonIntegratedType = ({ type }: Props) => {
  const { name, placeholderDescription, icon, image, iconBackground } =
    updateTypeOptionMap[type]

  const handleConnect = async () => {
    if (type === UpdateTypes.looker) {
      const resp = await lookerIntegrationPreferences.get({ id: '1' })
      if (resp.data?.method?.id === 'basic') {
        // for the existing tennants we need to support connecting to looker without oauth
        // where to manage connections in analytics tab
        newTabTo(ROUTES.SETTINGS.ANALYTICS)
        return
      }
    }
    if (type === UpdateTypes.clickup) {
      await askClickupWorkspacePermissions()
    }
  }

  return (
    <>
      <Widget>
        <Placeholder>
          <Flex alignItems="center" gap="s-8" py="s-24">
            <Avatar
              useIcon="LogoRevolut"
              color={Token.color.white}
              bg={Token.color.blue}
              variant="square"
            />
            <Icon name="Link" />
            <Avatar image={image} useIcon={icon} bg={iconBackground} />
          </Flex>
          <Placeholder.Title>
            <FormattedMessage
              values={{ name }}
              defaultMessage="Connect {name} to get started"
              id="performance.goalForm.connection.placeholder.title"
            />
          </Placeholder.Title>
          <Placeholder.Description>{placeholderDescription}</Placeholder.Description>
          <Placeholder.Action useIcon="Link" onClick={handleConnect}>
            <FormattedMessage
              values={{ name }}
              defaultMessage="Connect {name}"
              id="performance.goalForm.connection.placeholder.cta"
            />
          </Placeholder.Action>
        </Placeholder>
      </Widget>
    </>
  )
}
