import { Color, IconName, Token } from '@revolut/ui-kit'
import { ImageProp } from '@revolut/ui-kit/types/dist/types'
import { UpdateTypes } from '@src/interfaces/kpis'
import { PermissionTypes } from '@src/store/auth/types'

export const updateTypeOptionMap: Record<
  UpdateTypes,
  {
    name: string
    description: string
    image?: ImageProp
    icon?: IconName
    iconBackground?: Color
    requiredIntegrationPermission?: PermissionTypes
    placeholderDescription?: string
  }
> = {
  // Ordered in a way they should be displayed
  [UpdateTypes.manual]: {
    name: 'Manual',
    description: 'Manually update the current value',
    icon: 'Pencil',
  },
  [UpdateTypes.sql]: {
    name: 'SQL',
    description: 'Connect your goals via SQL',
    icon: 'Database',
    iconBackground: Token.color.actionLabel,
  },
  [UpdateTypes.roadmap]: {
    name: 'Jira',
    description: 'Use Jira to automatically update your progress',
    placeholderDescription: 'Provide access to you Jira account in 2 easy steps',
    icon: 'Jira|image',
    iconBackground: Token.color.actionLabel,
    // TODO https://revolut.atlassian.net/browse/REVC-6711
    // for now prevent from display
    requiredIntegrationPermission: PermissionTypes.ViewJiraIntegration,
  },
  [UpdateTypes.clickup]: {
    name: 'Clickup',
    description: 'Connect your Clickup tasks to track progress',
    placeholderDescription: 'Provide access to you ClickUp account in 3 easy steps',
    requiredIntegrationPermission: PermissionTypes.ViewClickupIntegration,
    image: {
      default: 'https://assets.revolut.com/assets/apps/ClickUp.webp',
      '2x': 'https://assets.revolut.com/assets/apps/ClickUp@2x.webp',
      '3x': 'https://assets.revolut.com/assets/apps/ClickUp@3x.webp',
    },
  },
  [UpdateTypes.looker]: {
    name: 'Looker',
    description: 'Use a Look to automatically update the current value',
    placeholderDescription: 'Provide access to you Looker account in 3 easy steps',
    image: {
      default: 'https://assets.revolut.com/assets/apps/Looker.webp',
      '2x': 'https://assets.revolut.com/assets/apps/Looker@2x.webp',
      '3x': 'https://assets.revolut.com/assets/apps/Looker@3x.webp',
    },
    // TODO https://revolut.atlassian.net/browse/REVC-6712
    // for now prevent from display
    requiredIntegrationPermission: 'cant-access-yet' as PermissionTypes, // PermissionTypes.ViewLookerIntegration,
  },
  [UpdateTypes.tableau]: {
    name: 'Tableau',
    description: 'Connect your Tableau tasks to track progress',
    placeholderDescription: 'Provide access to you Tableau account in 2 easy steps',
    // TODO https://revolut.atlassian.net/browse/REVC-6764
    // for now prevent from display
    requiredIntegrationPermission: 'cant-access-yet' as PermissionTypes, // PermissionTypes.ViewTableauIntegration,
    image: {
      default: 'https://assets.revolut.com/assets/apps/Tableau.webp',
      '2x': 'https://assets.revolut.com/assets/apps/Tableau@2x.webp',
      '3x': 'https://assets.revolut.com/assets/apps/Tableau@3x.webp',
    },
  },
}
